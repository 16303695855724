import React from "react";
import {
    buildCardPad,
    getObjectValueByPath,
    setObjectValueByPath
} from "../../../utils/Global";
import {
    appConfig,
} from "../../../utils/constants";
import CardPad from "./CardPad";
import $ from "jquery";
import CardList from "./CardList";
import AutoHeight from "../../shared/AutoHeight";

const USED_CAR_PARAMS = {
    mileage: "Пробег",
    registrationYear: "Год первой регистрации",
    ownerCount: "Кол-во владельцев",
    state: "Техническое состояние",
    warrantyExpiration: "Срок истечения гарантии",
    certificateNumber: "СТС",
    pledgeNumber: "Номер залога",
}
const STATES_LIST = {
    EXCELLENT: "отличное",
    GOOD: "хорошее",
    AVERAGE: "среднее",
    NEED_REPAIR: "требует ремонта",
}

class CardPageTabs extends React.Component {
    state = {
        selectedIndex: null
    };

    Retab = (e) => {
        $(e.target)
            .addClass("selected")
            .siblings()
            .removeClass("selected")
            .parent("ul")
            .next("ul.card-page-tabs__card-content-container")
            .children("li.card-content-container__card-content-item")
            .eq($(e.target).index())
            .addClass("selected")
            .siblings()
            .removeClass("selected");
        if ($(e.target).hasClass("big") && $(e.target).index() === 1) {
            $(".card-page-tabs__options-legend").addClass("hidden");
        } else {
            $(".card-page-tabs__options-legend").removeClass("hidden");
        }

        this.setState({selectedIndex: $(e.target).index()});
    }

    render() {
        const {data, clientConfig, sessionConfig} = this.props;
        let usedParams = {};
        if(data.used){
            Object.keys(USED_CAR_PARAMS).forEach((key, index) => {
                if(data.used[key]){
                    usedParams[`used${index + 1}`] = {
                        name: USED_CAR_PARAMS[key],
                        value: key !== "state" ? data.used[key] : STATES_LIST[data.used[key]],
                        base: true
                    }
                }
            })
        }
        data.attrs.extended = {
            ...usedParams,
            e3: clientConfig.cardVinType
                ? {
                    name: "ID",
                    value: data.sku,
                    base: true
                }
                : {},
            e4: data.labels.special
                ? {
                    name: appConfig.systemMessages.specialoffer,
                    base: false,
                    value: data.labels.special
                }
                : {},
            e5: data.extra
                ? {
                    name: appConfig.systemMessages.extraequipment,
                    value: data.extra,
                    base: false
                }
                : {},
            e6: data.description
                ? {
                    name: appConfig.systemMessages.specialofferdescr,
                    value: data.description.replace(/<[^>]*>?/gm, ''),
                    base: false
                }
                : {}
        };

        const pad = buildCardPad({
            ...data.attrs.extended,
            ...data.attrs.body,
            ...data.attrs.interior,
            ...data.attrs.safety,
            ...data.attrs.equip,
            ...data.attrs.warranty
        });

        if (
            clientConfig.cardAttrChangeValueNew &&
            getObjectValueByPath(
                data.attrs,
                clientConfig.cardAttrChangeValueNew.get.attr,
                clientConfig.cardAttrChangeValueNew.get.value
            )
        )
            setObjectValueByPath(
                data.attrs,
                clientConfig.cardAttrChangeValueNew.set.attr,
                clientConfig.cardAttrChangeValueNew.set.value
            );

        return (
            <div className="card-page__card-page-tabs">
                <div className="card-page-tabs__options-legend">
                    <div className="card-content-pad__car-pad-item">
                        Базовая Комплектация
                    </div>
                    <div className="card-content-pad__car-pad-item additional">
                        Доп. опции
                    </div>
                </div>
                <ul className="card-page-tabs__card-tabs-container">
                    <li
                        className={
                            "card-tabs-container__card-tab-item big" +
                            ((pad.additional && Object.keys(pad.additional).length === 0) ||
                            clientConfig.cardActiveTabItem === 1 ||
                            !clientConfig.cardActiveTabItem
                                ? " selected"
                                : "")
                        }
                        onClick={this.Retab}
                    >
                        Комплектация
                    </li>
                    <li
                        className={
                            "card-tabs-container__card-tab-item big" +
                            (clientConfig.cardActiveTabItem === 2
                                ? " selected"
                                : "")
                        }
                        onClick={this.Retab}
                    >
                        Тех.характеристики
                    </li>
                    {clientConfig.cardActiveTabItem &&
                    pad.additional &&
                    Object.keys(pad.additional).length > 0 && (
                        <li
                            className={
                                "card-tabs-container__card-tab-item big" +
                                (clientConfig.cardActiveTabItem === 3
                                    ? " selected"
                                    : "")
                            }
                            onClick={this.Retab}
                        >
                            Доп.опции
                        </li>
                    )}
                </ul>
                <ul className="card-page-tabs__card-content-container">
                    <li
                        className={
                            "card-content-container__card-content-item" +
                            ((pad.additional && Object.keys(pad.additional).length === 0) ||
                            clientConfig.cardActiveTabItem === 1 ||
                            !clientConfig.cardActiveTabItem
                                ? " selected"
                                : "")
                        }
                    >
                        <ul className="card-page-tabs__card-tabs-container">
                            <li
                                className="card-tabs-container__card-tab-item small selected"
                                onClick={this.Retab}
                            >
                                Кратко
                            </li>
                            <li
                                className="card-tabs-container__card-tab-item small"
                                onClick={this.Retab}
                            >
                                Подробно
                            </li>
                        </ul>
                        <ul className="card-page-tabs__card-content-container tut">
                            <li className="card-content-container__card-content-item selected">
                                <div className="card-content-item__card-content-bar">
                                    <CardPad
                                        name="body"
                                        type="short"
                                        data={data.attrs.body}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="interior"
                                        type="short"
                                        data={data.attrs.interior}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                </div>
                                <div className="card-content-item__card-content-bar">
                                    <CardPad
                                        name="safety"
                                        type="short"
                                        data={data.attrs.safety}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="equip"
                                        type="short"
                                        data={data.attrs.equip}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                </div>
                            </li>
                            <li className="card-content-container__card-content-item">
                                <div className="card-content-item__card-content-bar">
                                    <CardPad
                                        name="extended"
                                        type="full"
                                        data={data.attrs.extended}
                                        description={data.description}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="main"
                                        type="full"
                                        data={data.attrs.main}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="body"
                                        type="full"
                                        data={data.attrs.body}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="interior"
                                        type="full"
                                        data={data.attrs.interior}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="safety"
                                        type="full"
                                        data={data.attrs.safety}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                </div>
                                <div className="card-content-item__card-content-bar">
                                    <CardPad
                                        name="equip"
                                        type="full"
                                        data={data.attrs.equip}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                    <CardPad
                                        name="warranty"
                                        type="full"
                                        data={data.attrs.warranty}
                                        sessionConfig={sessionConfig}
                                        clientConfig={clientConfig}
                                    />
                                </div>
                            </li>
                            <div className="card-content-item__additinal-description">
                                дополнительно установленные опции к базовой комплектации
                            </div>
                        </ul>
                    </li>
                    <li
                        className={
                            "card-content-container__card-content-item" +
                            (clientConfig.cardActiveTabItem &&
                            clientConfig.cardActiveTabItem === 2
                                ? " selected"
                                : "")
                        }
                    >
                        <CardList data={data}/>
                    </li>
                    <li
                        className={
                            "card-content-container__card-content-item" +
                            (clientConfig.cardActiveTabItem &&
                            (pad.additional && Object.keys(pad.additional).length > 0) &&
                            clientConfig.cardActiveTabItem === 3
                                ? " selected"
                                : "")
                        }
                    >
                        <div className="card-content-pad__car-pad-title">
                            Дополнительно установленные опции к базовой комплектации
                        </div>

                        <div className="card-content-item__card-content-bar">
                            <CardPad
                                name="extended"
                                type="full"
                                additional="true"
                                data={data.attrs.extended}
                                description={data.description}
                            />
                            <CardPad
                                name="main"
                                type="full"
                                additional="true"
                                data={data.attrs.main}
                            />
                            <CardPad
                                name="body"
                                type="full"
                                additional="true"
                                data={data.attrs.body}
                            />
                            <CardPad
                                name="interior"
                                type="full"
                                additional="true"
                                data={data.attrs.interior}
                            />
                            <CardPad
                                name="safety"
                                type="full"
                                additional="true"
                                data={data.attrs.safety}
                            />
                        </div>
                        <div className="card-content-item__card-content-bar">
                            <CardPad
                                name="equip"
                                type="full"
                                additional="true"
                                data={data.attrs.equip}
                            />
                        </div>
                    </li>
                </ul>
                <AutoHeight/>
            </div>
        );
    }
}

export default CardPageTabs;
