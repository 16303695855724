import React from "react";
import { digitBeautify } from "../../utils/Global.js";

class PriceBlock extends React.Component {
  render() {
    const { car, clientConfig } = this.props;
    return (
      <div className={"price-block"}>
        <div>
          {clientConfig.searchPriceType ? (
            clientConfig.searchPriceType === 1 ? (
              <span>
                {digitBeautify(car.retailPrice)}
                <span className="rouble to-result-cell" />
              </span>
            ) : clientConfig.searchPriceType === 2 ? (
              <span>
                {digitBeautify(car.maxPrice)}
                <span className="rouble to-result-cell" />
              </span>
            ) : (
                  clientConfig.searchPriceType === 3 && (
                    <span>
                      {digitBeautify(car.minPrice)}
                      <span className="rouble to-result-cell" />
                    </span>
                  )
                )
          ) : car.retailPriceVisible ? (
            <span>
              {digitBeautify(car.retailPrice)}
              <span className="rouble to-result-cell" />
            </span>
          ) : (
                <span className="by-request">
                  {clientConfig.searchRequestText || (clientConfig.searchRequestDescription && car.description ? car.description : "по запросу")}
                </span>
              )}
        </div>
        <div>
          {clientConfig.searchPriceType
            ? clientConfig.searchPriceType === 1 &&
            car.basePrice > car.retailPrice > 0 && (
              <span key={car.id}>
                {digitBeautify(car.basePrice)}
                <span className="rouble to-result-cell" />
              </span>
            )
            : car.basePriceVisible &&
            car.basePrice > 0 &&
            car.basePrice >= car.retailPrice &&
            (car.basePrice !== car.retailPrice ||
              !car.retailPriceVisible) && (
              <span>
                {digitBeautify(car.basePrice)}
                <span className="rouble to-result-cell" />
              </span>
            )}
        </div>
        <div>
          {clientConfig.searchMarketingPriceVisible && car.marketingPrice > 0 && (
            <span>
              {digitBeautify(car.marketingPrice)}
              <span className="rouble to-result-cell" />
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default PriceBlock;
