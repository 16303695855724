const offers = `
    totalCount
    edges {
      node {
        id: databaseId
        color {
          name
        }
        dealer {
          id
          name
          phoneNumber
          address {
            district
            address
          }
          region {
            name
          }
        }
        availability
        modelYear
        additionalEquipment
        special
        comment
        prices {
          sale {
            amount
            visible
          }
          base {
            amount
            visible
          }
          marketing {
            amount
            visible
          }
        }
        photoUrls
        catalogPhotoUrl(width: 170)
        sku
        updateAt 
        year 
        edition {
          versionName
          make {
            name
          }
          model {
            name
          }
          specifications {
            name
            value
          }          
          description {
            name
            features {
              name
              value
              ins {
                base
                description
              }
            }
          }
          versionName
          headline
        }
      }
    }
`
const filters = `
    id
    name
    ... on NumberFilter {
        min
        max
        precision
    }
    ... on EnumFilter {
        multiple
        depend
        values {
          id
          name
          count
          checked
          slug
        }
    }
    ... on BooleanFilter {
        id
        name
    }
`

export const getFilters = `
query getFilters(
  $expressions: [String],
  $domain: Domain,
) {
  filters(custom:["1101", "1102"], geo: {ids: [], radius: 0}, expressions: $expressions, domain: $domain) {
    ${filters}
  }
}
`

export const getCarsList = `
query getCarsList(
  $first: Int!,
  $offset: Int!, 
  $expressions: [String],
  $sort: [OfferSort],
  $geo: GeoInput,
  $domain: Domain,
) {
  offers(first: $first, offset: $offset, expressions: $expressions, sort: $sort, domain: $domain, geo: $geo) {
    ${offers}
  }
  filters(custom:["1101", "1002", "1102", "1103", "1401", "2101", "2201", "2301", "2302", "3103", "3104", "5201", "5413", "5418", "6101", "6104", "6201", "6301"], geo: {ids: [], radius: 0}, expressions: $expressions, domain: $domain) {
    ${filters}
  }
}
`

export const getCarsListWithAllRegions = `
query getCarsList(
  $first: Int!,
  $offset: Int!,
  $expressions: [String],
  $sort: [OfferSort],
  $corpus: RegionCorpus,
  $geo: GeoInput,
  $domain: Domain
) {
  offers(first: $first, offset: $offset, expressions: $expressions, sort: $sort, domain: $domain, geo: $geo) {
    ${offers}
  }
  filters(custom:["1101", "1002", "1102", "1103", "1401", "2101", "2201", "2301", "2302", "3103", "3104", "5201", "5413", "5418", "6101", "6104", "6201", "6301"], geo: {ids: [], radius: 0}, expressions: $expressions, domain: $domain) {
    ${filters}
  }
  regions(query: "", after: null, first: 0, corpus: $corpus) {
    edges {
        node {
            id
            name
        }
    }
  }
}
`

export const getCarsListWithSelectedRegions = `
query getCarsList(
  $first: Int!,
  $offset: Int!,
  $expressions: [String],
  $sort: [OfferSort],
  $ids: [ID!]!,
  $geo: GeoInput,
  $domain: Domain
) {
  offers(first: $first, offset: $offset, expressions: $expressions, sort: $sort, domain: $domain, geo: $geo) {
    ${offers}
  }
  filters(custom:["1101", "1002", "1102", "1103", "1401", "2101", "2201", "2301", "2302", "3103", "3104", "5201", "5413", "5418", "6101", "6104", "6201", "6301"], geo: {ids: $ids, radius: 0}, expressions: $expressions, domain: $domain) {
    ${filters}
  }
  regions: nodes(ids: $ids) {
    ... on Region {
        id
        name
    }
  }
}
`

export const getCard = `
query getCard(
  $slug: String!
) {
  offer(slug: $slug, domain: {channel: DEALER}) {
    id: databaseId
    color {
      name
      code
    }
    dealer {
      id:databaseId
      key
      name
      phoneNumber
      expression
      address {
        district
        address
        locality
      }
      region {
        name
      }
    }
    availability
    modelYear
    additionalEquipment
    special
    comment
    photoUrls
    catalogPhotoUrl(width: 500)
    sku
    updateAt
    year 
    active
    prices {
        sale {
          amount
          visible
        }
        base {
          amount
          visible
        }
        marketing {
          amount
          visible
        }
      }
    edition {
      versionName
      make {
        name
      }
      model {
        name
      }
      specifications {
        name
        unit
        value
        has
        ins {
          description
        }
      }          
      description {
        name
        features {
          name
          value
          base
          ins {
            base
            description
          }
        }
        flags {
          i
          label
          has
          ins
        }
      }
      versionName
      interior
    }
    used {
      mileage
      ownerCount
      state
      pledgeNumber
      certificateNumber
      warrantyExpiration
      registrationYear
    }
  }
}
`