import React from "react";
import {
  rpc,
  searchTextReplace,
  digitBeautify,
  clearEmpty,
  replaceSubstringByValue,
  phoneValidate,
  emailValidate,
} from "../../utils/Global";
import {
  systemLocations,
  appConfig,
} from "../../utils/constants";
import $ from "../../vendor/jquery.jstyling.min";
import "jquery.maskedinput/src/jquery.maskedinput.js";
import ConfigContext, { InfoContext } from "../../context/ConfigContext";
import addToDataLayer from "../../utils/tracker";

class OfferForm extends React.Component{
  state = {
      offerSent: 0,
      phoneListDropped: 0,
      phoneListSelected: "ru",
      offerRequiredFields: [],
  }

  componentWillMount = () => {
    if (this.context.callkeeperEventManager === 2) {
      this.extra = {};
      const that = this;

      Promise.all([
        new Promise(function(resolve, reject) {
          rpc.invoke("CallKeeper.f.getUtm", null, resolve, reject);
        }),
        new Promise(function(resolve, reject) {
          rpc.invoke(
            "CallKeeper.a.ga_clid",
            null,
            function(result) {
              resolve({ ga_client_id: result });
            },
            reject
          );
        }),
      ]).then((values) => {
        values.unshift({});
        that.extra = Object.assign.apply(this, values);
      });
    }
  }
  componentDidMount = () => {
    const {
      offerRequiredFields,
      offerAgreement,
      offerAgreementRemote,
      offerAgreementDefaultChecked,
    } = this.context;
    this.setState({
      offerRequiredFields: offerRequiredFields || ["offer-phone"],
    });
    $.mask.definitions["9"] = "";
    $.mask.definitions["_"] = "[0-9]";
    if (offerAgreementDefaultChecked !== false) {
      $("#offer-agreement").trigger("click");
      $("#offer-agreement-remote").trigger("click");
    }
    $("#offer-phone").mask("+7 (___) ___-__-__", {
      placeholder: "+7 (___) ___-__-__",
    });
    if (offerAgreement || offerAgreementRemote) {
      $.jStyling.createCheckbox($("input[type=checkbox]"));
    }
  }
  handleKeyUp = (e) => {
    $(e.target).removeClass("alerted");
  }
  sendOffer = (url, car) => {
    const { offerRequiredFields } = this.state;
    const {
      offerType,
      offerAgreement,
      offerAgreementRemote,
      callkeeperEventManager,
      callkeeperPhoneNumber,
      callkeeperEventBrandModel,
      comagicEventManager,
      callbackhunterEventManager,
      perezvoniEventManager,
      calltouchEventManager,
      calltouchId,
      offerRedirectTimeout,
    } = this.context;

    offerRequiredFields.forEach((field) =>
      $(`#${field}`).attr({ "data-require": 1 })
    );

    if (
      (offerAgreement == null ||
        (offerAgreement &&
          document.getElementById("offer-agreement").checked &&
          (offerType === 3 || offerType === 4))) &&
      (offerAgreementRemote == null ||
        (offerAgreementRemote &&
          document.getElementById("offer-agreement-remote").checked &&
          (offerType === 3 || offerType === 4)))
    ) {
      $("[data-require]")
        .filter(function() {
          return $(this).val() === "";
        })
        .each((index, item) => {
          $(item).addClass("alerted");
        });
      if (offerAgreement || offerAgreementRemote) {
        $("input[type=checkbox][data-require]")
          .filter(function() {
            return !$(this).attr("checked");
          })
          .each((item) => {
            $(item)
              .parent()
              .parent()
              .addClass("alerted");
          });
      }
      if (
        $("#offer-phone").val() !== "" &&
        !phoneValidate($("#offer-phone").val())
      ) {
        $("#offer-phone").addClass("alerted");
      }
      if (
        $("#offer-email").val() !== "" &&
        !emailValidate($("#offer-email").val())
      ) {
        $("#offer-email").addClass("alerted");
      }
      if ($(".alerted").length === 0) {
        window.dataLayer.push({ event: "want.send" });
        if (callkeeperEventManager === 1) {
          rpc.invoke(
            ["ckForms", "ckEvent"],
            Object.assign(
              {},
              {
                tel: $("#offer-phone").val(),
                person: $("#offer-name").val(),
                partner: "ИЛСА",
                manager:
                  "+7 " +
                  (typeof callkeeperPhoneNumber == "object" &&
                  callkeeperPhoneNumber[car.dealer.key.toLowerCase()]
                    ? callkeeperPhoneNumber[car.dealer.key.toLowerCase()]
                    : callkeeperPhoneNumber ||
                      this.props.car.dealer.phoneNumber),
              },
              callkeeperEventBrandModel
                ? { param1: car.brand + " " + car.model, param2: car.name }
                : {}
            )
          );
        }
        if (comagicEventManager) {
          rpc.invoke(["ComagicWidget", "sitePhoneCall"], {
            phone: $("#offer-phone").val(),
          });
        }
        if (callbackhunterEventManager) {
          rpc.invoke("CBHCore.api.sendCall", {
            phone: $("#offer-phone").val(),
          });
        }
        if (perezvoniEventManager) {
          rpc.invoke("PRZV.Api.addLead", {
            phone: $("#offer-phone").val(),
            name:
              "Имя клиента: " +
              $("#offer-name").val() +
              ", Дилер: " +
              car.name +
              ", Модель: " +
              car.model,
          });
        }
        if (calltouchEventManager && calltouchId) {
          rpc.invoke("ctw.createRequest", [
            calltouchId[car.dealer.id] || calltouchId,
            $("#offer-phone")
              .val()
              .replace(/[^0-9.]/g, ""),
          ]);
        }
        this.setState({ offerSent: 1 });
        const data = {
          client: {
            name: $("#offer-name").val() || "Имя не заполнено",
            email: $("#offer-email").val(),
            phoneNumber: $("#offer-phone").val(),
          },
          callkeeper: callkeeperEventManager === 2 ? this.extra : null,
          comment: $("#offer-comment").val(),
          uri: url + "#/car/" + car.id,
        };

        const closeHandler = this.props.onClose;

        const fs = [];

        if (callkeeperEventManager) {
          fs.push("callkeeper");
        }

        if (
          callkeeperEventManager ||
          perezvoniEventManager ||
          calltouchEventManager ||
          comagicEventManager
        ) {
          fs.push("callback");
        }

        $.ajax({
          type: "POST",
          url:
            systemLocations.offersLocation.replace("{id}", car.id) +
            "?" +
            "fs=" +
            fs.join(","),
          headers: { Authorization: appConfig.offerConfig.offerKey },
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          success: function(e) {
            window.dataLayer.push({
              event: "ilsa.requestSent",
              request: e,
              offerId: "" + car.id,
            });
            addToDataLayer("purchase", {
              ecommerce: {
                purchase: {
                  actionField: {
                    id: "" + e.id,
                  },
                  products: [
                    {
                      id: "" + car.id,
                      price: car.retailPrice.toFixed(2),
                      quantity: 1,
                    },
                  ],
                },
              },
            });
            if (offerRedirectTimeout) {
              this.setState({ offerSent: 2 });
              setTimeout(function() {
                closeHandler();
              }, 5000);
            } else closeHandler();
          }.bind(this),
        });
      }
    }
  }
  handleCheckboxClick = (e) => {
    if (e.target.tagName !== "A") {
      $("#offer-agreement").trigger("click", e);
      if (!$("#offer-agreement").get(0).checked)
        $("input").removeClass("alerted");
    }
  }
  handleCheckboxRemoteClick = (e) => {
    if (e.target.tagName !== "A") {
      $("#offer-agreement-remote").trigger("click", e);
      if (!$("#offer-agreement-remote").get(0).checked)
        $("input").removeClass("alerted");
    }
  }
  togglePhoneNumberType = (type, mask) => {
    this.setState({
      phoneListDropped: !this.state.phoneListDropped,
      phoneListSelected:
        typeof type !== "object" ? type : this.state.phoneListSelected,
    });
    $("#offer-phone").mask(mask, { placeholder: mask });
  }

  render() {
    const { car } = this.props
    const { offerRequiredFields } = this.state;
    const {
      offerType,
      offerCallbackTitle,
      searchPriceType,
      offerAgreement,
      offerAgreementRemote,
      offerCallbackFootnote,
      offerCarTitle,
      offerPrice,
      searchRequestText,
      searchMarketingPriceVisible,
      offerCarCode,
      offerCallbackDescription,
      offerRedirectText,
    } = this.context;
    var phoneMasks = {
        ru: "+7 (___) ___-__-__",
        by: "+375 (___) ___-__-__",
        kz: "+77 (__) ___-__-__",
        md: "+373 (___) ___-__",
        uz: "+998 (___) ___-__-__",
        ua: "+380 (__) ___-__-__",
        ot: "__________",
      };

    return (
      <div className="offer-page__offer-form">
        <ul className="card-page-tabs__card-content-container">
          <li className="card-content-container__card-content-item selected">
            <div
              id="offer-title-text"
              className="offer-form__form-title"
              dangerouslySetInnerHTML={{
                __html: offerCarTitle
                  ? offerCarTitle
                  : "Интересует автомобиль:",
              }}
            />
            <div className="offer-form__form-value">
              <span id="offer-car-brand" className="car-title__brand">
                {car.brand}
              </span>{" "}
              <span id="offer-car-model" className="car-title__model">
                {car.model}
              </span>{" "}
              <span id="offer-car-complectation">{car.complectation}</span>
              <span id="offer-car-color">
                , <br />
                {car.color}
              </span>
            </div>
            <div className="offer-form__form-title" id="offer-car-price">
              {offerPrice && (
                <div className="offer-form__form-title price">
                  Цена:
                  <div className="offer-form__form-value">
                    {searchPriceType ? (
                      searchPriceType === 1 ? (
                        <span>
                          {digitBeautify(car.retailPrice)}
                          <span className="rouble to-offer" />
                        </span>
                      ) : searchPriceType === 2 && (
                        <span>
                          {digitBeautify(car.maxPrice)}
                          <span className="rouble to-result-cell" />
                        </span>
                      )
                    ) : car.retailPriceVisible ? (
                      <span>
                        {digitBeautify(car.retailPrice)}
                        <span className="rouble to-offer" />
                      </span>
                    ) : (
                      <span className="by-request">
                        {searchRequestText ? searchRequestText : "по запросу"}
                      </span>
                    )}
                  </div>
                  <div className="offer-form__form-value">
                    {searchPriceType ? ((
                      searchPriceType === 1 &&
                      this.props.car.basePrice >
                        this.props.car.retailPrice >
                        0) && (
                        <span>
                          {digitBeautify(car.basePrice)}
                          <span className="rouble to-offer" />
                        </span>
                      )
                    ) : car.basePriceVisible && this.props.car.basePrice > 0 && (
                      <span>
                        {digitBeautify(car.basePrice)}
                        <span className="rouble to-offer" />
                      </span>
                    )}
                  </div>
                  <div className="offer-form__form-value">
                    {(searchMarketingPriceVisible &&
                    this.props.car.marketingPrice > 0) && (
                      <span>
                        {digitBeautify(car.marketingPrice)}
                        <span className="rouble to-offer" />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="offer-form__form-title" id="offer-car-location">
              Данный автомобиль находится {this.props.car.availability === "IN_STOCK" ? "в наличии" : "в пути"}{" "}
              {this.props.car.availability === "IN_STOCK"
                ? "в официальном дилерском центре"
                : "и будет доставлен в официальный дилерский центр"}
              :{" "}
            </div>
            <div
              className={
                "offer-form__form-value" + (offerType === 4 ? " markup" : "")
              }
            >
              <span>{car.dealer.name}</span>
              <br />
              <span>{`${car.dealer.address.locality ? 
                  `${car.dealer.address.locality}, ` : ""}${car.dealer.address.address}`}</span>
              {(offerType === 4 || offerCarCode) && (
                <div id="offer-car-vin">
                  <span>{offerCarCode ? offerCarCode : "Код автомобиля:"}</span>
                  <span> </span>
                  <span>{car.sku}</span>
                </div>
              )}
            </div>
            {(offerType === 0 || offerType === 2) && (
              <div id="offer-dealer-phone">
                <div className="offer-form__form-title">
                  Вы можете позвонить по телефону дилерского центра:{" "}
                </div>
                <div className="offer-form__form-value">{car.phone}</div>
              </div>
            )}
            {offerType === 1 ||
            offerType === 2 ||
            offerType === 3 ||
            offerType === 4 ? (
              <div id="offer-callback-title">
                <div
                  className="offer-form__form-title pb-10"
                  dangerouslySetInnerHTML={{
                    __html:
                      offerType === 1 ||
                      offerType === 2 ||
                      offerType === 3 ||
                      offerType === 4
                        ? offerCallbackTitle
                          ? replaceSubstringByValue(offerCallbackTitle, car)
                          : offerType === 2
                          ? "или отправить заявку на обратный звонок"
                          : "Заявка на обратный звонок"
                        : "или отправить заявку на обратный звонок",
                  }}
                />
                <form>
                  <input
                    autoFocus
                    id="offer-name"
                    className="offer-form__form-control offer-form__form-input"
                    placeholder="Ваше имя"
                    onKeyUp={this.handleKeyUp}
                  />
                  <div className="offer-form__phone-wrapper">
                    <input
                      id="offer-phone"
                      className="offer-form__form-control offer-form__form-input"
                      placeholder={phoneMasks[this.state.phoneListSelected]}
                      onKeyUp={this.handleKeyUp}
                    />
                    {this.state.phoneListDropped ? (
                      <div
                        className="phone-wrapper__phone-dropdown-list dropped"
                        onMouseLeave={this.togglePhoneNumberType.bind(
                          this,
                          this.state.phoneListSelected,
                          phoneMasks[this.state.phoneListSelected]
                        )}
                      >
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "ru",
                            phoneMasks["ru"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item ru"
                        >
                          Россия (+7)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "by",
                            phoneMasks["by"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item by"
                        >
                          Белоруссия (+375)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "kz",
                            phoneMasks["kz"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item kz"
                        >
                          Казахстан (+77)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "md",
                            phoneMasks["md"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item md"
                        >
                          Молдова (+373)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "uz",
                            phoneMasks["uz"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item uz"
                        >
                          Узбекистан (+998)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "ua",
                            phoneMasks["ua"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item ua"
                        >
                          Украина (+380)
                        </div>
                        <div
                          onClick={this.togglePhoneNumberType.bind(
                            this,
                            "ot",
                            phoneMasks["ot"]
                          )}
                          className="phone-dropdown-list__dropdown-list-item ot"
                        >
                          Прочие
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          "phone-wrapper__phone-dropdown-list " +
                          this.state.phoneListSelected
                        }
                        onClick={this.togglePhoneNumberType}
                      />
                    )}
                  </div>
                  <input
                    id="offer-email"
                    className="offer-form__form-control offer-form__form-input"
                    placeholder={`E-mail ${
                      offerRequiredFields.indexOf("offer-email") === -1
                        ? "(не обязательно)"
                        : ""
                    }`}
                    onKeyUp={this.handleKeyUp}
                  />
                  <textarea
                    id="offer-comment"
                    className="offer-form__form-control offer-form__form-textarea"
                    placeholder={`Комментарий ${
                      offerRequiredFields.indexOf("offer-comment") === -1
                        ? "(не обязательно)"
                        : ""
                    }`}
                    onKeyUp={this.handleKeyUp}
                  />
                  <div
                    id="offer-callback-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        offerCallbackDescription &&
                        (offerType === 2 || offerType === 3)
                          ? offerCallbackDescription
                          : "<p>Заполните поля и нажмите Отправить. Наш менеджер отдела продаж свяжется с Вами по телефону.</p>",
                    }}
                  />
                  {offerAgreement && (offerType === 3 || offerType === 4) ? (
                    <div className="pb-10">
                      <input
                        type="checkbox"
                        id="offer-agreement"
                        onClick={this.handleKeyUp}
                      />
                      <label
                        htmlFor="offer-agreement"
                        onClick={this.handleCheckboxClick}
                        dangerouslySetInnerHTML={{
                          __html: offerAgreement,
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                  {offerAgreementRemote &&
                  (offerType === 3 || offerType === 4) ? (
                    <div className="pb-10">
                      <input
                        type="checkbox"
                        id="offer-agreement-remote"
                        onClick={this.handleKeyUp}
                      />
                      <label
                        htmlFor="offer-agreement-remote"
                        onClick={this.handleCheckboxRemoteClick}
                        dangerouslySetInnerHTML={{
                          __html: offerAgreementRemote,
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                  <div className="offer-form__submit-container">
                    {this.state.offerSent === 0 ? (
                      <InfoContext.Consumer>
                        {({ referrer }) => (
                          <div
                            className="button-green to-offer-form unselectable"
                            onClick={this.sendOffer.bind(this, referrer, car)}
                          >
                            ОТПРАВИТЬ
                          </div>
                        )}
                      </InfoContext.Consumer>
                    ) : this.state.offerSent === 2 ? (
                      <div
                        className="submit-container__processing-message"
                        dangerouslySetInnerHTML={{
                          __html:
                            offerRedirectText ||
                            "Ваш запрос отправлен. Через 5 секунд Вы будете перенаправлены на карточку авто.",
                        }}
                      />
                    ) : (
                      <div className="submit-container__processing-message">
                        Обработка запроса...
                      </div>
                    )}
                  </div>
                  {offerType === 3 ? (
                    <div id="offer-car-phone">
                      <div className="offer-form__form-title">
                        Или позвоните нам по телефону:{" "}
                      </div>
                      <div className="offer-form__form-title">{car.phone}</div>
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: offerCallbackFootnote,
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                  {offerType === 4 ? (
                    <div
                      id="offer-callback-footnote"
                      className="offer-form__callback-footnote"
                      dangerouslySetInnerHTML={{
                        __html: offerCallbackFootnote,
                      }}
                    />
                  ) : (
                    false
                  )}
                </form>
              </div>
            ) : (
              false
            )}
          </li>
        </ul>
      </div>
    );
  }
}

OfferForm.contextType = ConfigContext;
export default OfferForm;
