import React, { Component } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import graphqlClient from "./graphql/services/client";
import SearchPage from "./pages/SearchPage";
import ConfigContext, { InfoContext } from "./context/ConfigContext";
import { parentUrl, initFrame } from "./utils/Global";
import { appConfig, systemLocations } from "./utils/constants";
import CardPage from "./pages/CardPage";
import "./App.css";
import OfferPage from "./pages/OfferPage";
import AccessDenied from "./components/shared/AccessDenied";
import ScrollToTop, { ScrollToTopOnMount } from "./components/shared/ScrollToTop";

window.dataLayer = window.dataLayer || {};

class App extends Component {
  state = {
    loading: true
  };

  constructor(props) {
    super(props);

    initFrame();
      let url = null;
      if (process.env.NODE_ENV !== "production") {
        const params = new URLSearchParams(document.location.search);
        let customParentUrl = "";
        if (params.has("referrer")) {
            customParentUrl = params.get("referrer");
            sessionStorage.setItem("referrer", customParentUrl);
        } else {
            customParentUrl = sessionStorage.getItem("referrer");
        }
          url = new URL(customParentUrl);
      }else{
        url = new URL(new URLSearchParams(document.location.search).get("parentUrl"));
      }

      const referrer =
        url.hostname.replace(/^w{3}\./, "") + url.pathname.replace(/\/$/, "");

      fetch(`${systemLocations.serverLocation}?referrer=${referrer}&page=1`)
          .then(res => res.json())
          .then(res => {
            if (!res.setup) {
              this.setState({ loading: false, referrer: url.href });
              return;
            }
            const config = JSON.parse(res.setup);

            appConfig.runtimeConfig.orderfield = config.searchOrderField || "price";

            if (config.clientScrollCondition) {
              config.clientScroll = Object.entries(
                  config.clientScrollCondition
              ).some(([key, value]) => url.searchParams.get(key) === value)
                  ? 1
                  : 0;
            }

            this.setState({
              config: config,
              loading: false,
              referrer: url.href
            });

            if (config.clientCss) {
              const custom = document.createElement("style");
              custom.type = "text/css";
              if (custom.styleSheet) {
                custom.styleSheet.cssText = config.clientCss;
              } else {
                custom.appendChild(document.createTextNode(config.clientCss));
              }

              (
                  document.head || document.getElementsByTagName("head")[0]
              ).appendChild(custom);
            }
          });
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    if (!this.state.config) {
      return <AccessDenied />;
    }
    return (
      <Router>
        <ConfigContext.Provider value={this.state.config}>
          {this.state.config.clientScroll ? <ScrollToTopOnMount /> : null}
          <ScrollToTop>
            <InfoContext.Provider value={{ referrer: this.state.referrer }}>
              <Switch>
                <Route path="/claim/car/:id" component={OfferPage} />
                <Route path="/car/:id" component={CardPage} />
                <Route path="/" component={SearchPage} />
              </Switch>
            </InfoContext.Provider>
          </ScrollToTop>
        </ConfigContext.Provider>
      </Router>
    );
  }
}

export default App;
