import React from "react";
import ReactDOM from "react-dom";
import Link from "./Link";
import { withRouter } from "react-router-dom";

class CloseButton extends React.Component {
  handleClick = e => {
    const { history, location } = this.props;
    if (location.state && location.state.app) {
      e.preventDefault();
      history.goBack();
    }
  };

  click(e) {
    const el = ReactDOM.findDOMNode(this);
    el.click(e);
  }

  render() {
    return (
      <Link
        to={this.props.to}
        className={"close-button " + (this.props.className || "")}
        onClick={this.handleClick}
      />
    );
  }
}

export default withRouter(CloseButton);
