import React from "react";
import {
    replaceSubstringByValue, dateBeautify
} from "../../../utils/Global";
import {
    systemLocations,
} from "../../../utils/constants";
import Link from "../../shared/Link";
import Swipeable from "./Swipeable";
import PriceBlock from "../../shared/PriceBlock";
import {ButtonBlock} from "../../shared/ButtonBlock";
import NoCarImage from "../../../images/no-car-image.svg"


class CardPageTop extends React.Component {
    state = {
        cardImagesCurrentIndex: 0,
        cardImagesFloaterOffset: 0,
        cardPreviewsFloaterOffset: 0
    };

    toggleCardImageByPreview = (index) => {
        this.setState({
            cardImagesCurrentIndex: index,
            cardImagesFloaterOffset: -100 * index,
            cardPreviewsFloaterOffset: -Math.floor(index / 4) * 4 * 66
        });
    }

    toggleCardImageByButton = (direction, length) => {
        var index =
            (this.state.cardImagesCurrentIndex + direction) % length === -1
                ? length - 1
                : (this.state.cardImagesCurrentIndex + direction) % length;
        this.setState({
            cardImagesCurrentIndex: index,
            cardImagesFloaterOffset: -100 * index,
            cardPreviewsFloaterOffset: -Math.floor(index / 4) * 4 * 66
        });
    }

    render() {
        const props = this.props;
        const {clientConfig, car} = props;

        const photos = [];
        const previews = [];
        car.photos = clientConfig.cardGalleryType !== 1 && car.photos && car.photos.length > 0
            ? car.photos
            : [car.image]
        car.photos.map((photo, index) => {
            photos.push(
                <div
                    key={index}
                    className="card-images-floater__card-image-item"
                    style={{
                        backgroundImage:
                            `url(${photo ? photo.replace("auto", "500x375~/auto") : NoCarImage})`,
                        width: 100 / car.photos.length + "%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }}
                >
                    {
                        !photo && <span style={{
                            marginBottom: "50px",
                            opacity: "0.5",
                            fontSize: "13px",
                            textTransform: "uppercase"
                        }}>
                       Изображение отсутствует
                   </span>
                    }
                </div>
            );
            previews.push(
                <div
                    key={index}
                    onClick={this.toggleCardImageByPreview.bind(this, index)}
                    className={
                        "card-previews-floater__card-preview-item" +
                        (index === this.state.cardImagesCurrentIndex ? " selected" : "")
                    }
                    style={{
                        backgroundImage:
                            `url(${photo ? photo.replace("auto", "64x64!/auto") : NoCarImage})`
                    }}
                />
            );
        });

        return (
            <div className="card-page__card-page-top">
                <div className="table">
                    <h1 className="table-cell vertical-middle pl-10 pr-40 pb-5">
                        <span className="car-title__brand">{car.brand}</span>{" "}
                        <span className="car-title__model">{car.model}</span>{" "}
                        {car.complectation}
                    </h1>
                </div>
                <div className="card-page-top__card-page-details">
                    <div className={car.active ? "card-page-details__details-container" : "card-page-details__details-container disabled"}>
                        <div className="details-container__details-item">
                            <span>Год выпуска</span>
                            <span>{car.year}</span>
                        </div>
                        {car.active &&
                            <>
                                <div className="details-container__details-item">
                                    <span>Статус</span>
                                    <span>
                                        <span>{car.availability === "IN_STOCK" ? "в наличии" : "в пути"}</span>
                                        <span>
                                          {" "}
                                            (данные обновлены {dateBeautify(car.uptodate)})
                                        </span>
                                  </span>
                                </div>
                                <div className="details-container__details-item">
                                    <span>
                                    {clientConfig.searchLocation ? "Дилер" : "Город"}
                                    </span>
                                    <span>
                                        {clientConfig.searchLocation
                                            ? car.dealer.name + ", "
                                            : false}
                                        {car.location}
                                    </span>
                                </div>
                            </>
                        }
                        {clientConfig.cardPhoneTitle && (
                            <div className="details-container__details-item">
                                <span>Телефон</span>
                                <span>{"+7 " + props.car.dealer.phoneNumber}</span>
                            </div>
                        )}
                    </div>
                    {car.active &&
                        <div className="card-page-details__price-container">
                            <div className="price-container__price-value">
                                <div className="price-value__descr">ЦЕНА</div>
                                <PriceBlock {...props} />
                            </div>
                            <ButtonBlock
                                car={car}
                                clientConfig={clientConfig}
                                location="to-auto-card basic"
                            />
                            <ButtonBlock
                                additional
                                car={car}
                                clientConfig={clientConfig}
                                location="to-auto-card additional"
                            />
                        </div>
                    }
                </div>
                {!car.active &&
                <div className="disabled-car_label">
                    Автомобиль снят с продажи
                </div>
                }

                {clientConfig.searchCreditLink && (
                    <Link
                        to="credit"
                        params={car}
                        className="card-page-details__credit-link"
                    >
                        Рассчитать кредит
                    </Link>
                )}

                <div className="card-page-top__card-images-accessories-wrapper">
                    <Swipeable
                        onSwipedLeft={this.toggleCardImageByButton.bind(
                            this,
                            1,
                            photos.length
                        )}
                        onSwipedRight={this.toggleCardImageByButton.bind(
                            this,
                            -1,
                            photos.length
                        )}
                    >
                        <div
                            className={
                                "card-images-accessories-wrapper__card-images-previews-wrapper" +
                                (photos.length == 1 ? " single-image" : "")
                            }
                        >
                            {car.special && (
                                clientConfig.searchSaleLabel ? (
                                    clientConfig.searchSaleLabel !== 0 && (
                                        <img
                                            className="result-image-block__custom-search-sale-label"
                                            src={
                                                systemLocations.staticLocation +
                                                clientConfig.searchSaleLabel
                                            }
                                        />
                                    )
                                ) : (
                                    <img
                                        className="result-image-block__custom-search-sale-label"
                                        src={
                                            systemLocations.staticLocation +
                                            systemLocations.searchSaleLabel
                                        }
                                    />
                                )
                            )}
                            <div
                                className="card-images-previews-wrapper__navigate-button prev"
                                onClick={this.toggleCardImageByButton.bind(
                                    this,
                                    -1,
                                    photos.length
                                )}
                            />
                            <div
                                className="card-images-previews-wrapper__navigate-button next"
                                onClick={this.toggleCardImageByButton.bind(
                                    this,
                                    1,
                                    photos.length
                                )}
                            />
                            <div
                                className="card-images-previews-wrapper__card-images-floater"
                                style={{
                                    left: this.state.cardImagesFloaterOffset + "%",
                                    width: props.car.photos.length * 100 + "%"
                                }}
                            >
                                {photos}
                            </div>
                            <div
                                className="card-images-previews-wrapper__card-previews-floater"
                                style={{
                                    left: this.state.cardPreviewsFloaterOffset + "px",
                                    width: photos.length * 66 + "px"
                                }}
                            >
                                {previews}
                            </div>
                        </div>
                    </Swipeable>
                </div>

                <div className="pb-15 card-content-pad__car-pad-title interior">
                    {car.interior}
                </div>
                <div className="card-page-top__touch-info">
                    <div>
                        <div>Год выпуска:</div>
                        <div>{car.year}</div>
                    </div>
                    <div>
                        <div>Статус:</div>
                        <div>{car.availability === "IN_STOCK" ? "в наличии" : "в пути"}</div>
                    </div>
                    <div>
                        <div>Дата обновления:</div>
                        <div>{dateBeautify(car.uptodate)}</div>
                    </div>
                    <div>
                        <div>Город:</div>
                        <div>{car.location}</div>
                    </div>
                    <div>
                        <div>Дилер:</div>
                        <div>{car.dealer.name}</div>
                    </div>
                    <div>
                        <div>Цвет:</div>
                        <div>{car.color}</div>
                    </div>
                    <div>
                        <div>Салон:</div>
                        <div>
                            {"Обивка сидений: "}
                            {car.interiorObject.split(',')[0]},{" "}
                            {"Цвет обивки сидений: "}
                            {car.interiorObject.split(',')[1].replace("цвет", "")}
                        </div>
                    </div>
                    <div>
                        <div>
                            <PriceBlock {...props} />
                        </div>
                        <div>
                            <a
                                className="result-item__callto-button into-card-item"
                                target="_parent"
                                href={"tel:+7" + car.phone}
                            />

                            <ButtonBlock
                                car={car}
                                clientConfig={clientConfig}
                                location="to-auto-card basic"
                            />
                            <ButtonBlock
                                additional
                                car={car}
                                clientConfig={clientConfig}
                                location="to-auto-card additional"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardPageTop;
