import React from "react";
import { Redirect } from "react-router-dom";
import {canView, searchTextReplace} from "../utils/Global";
import { appConfig, systemLocations } from "../utils/constants";
import CloseButton from "../components/shared/CloseButton";
import OfferForm from "../components/OfferPage/OfferForm";
import AccessDenied from "../components/shared/AccessDenied";
import context, {withConfig} from "../context/ConfigContext";
import AutoHeight from "../components/shared/AutoHeight";
import addToDataLayer from "../utils/tracker";
import graphqlClient from "../graphql/services/client";
import {getCard} from "../graphql/queries";

class OfferPage extends React.Component {
  mounted = true;
  state = {
    loading: true
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    const {clientConfig} = this.props;

    const vars = {
      slug: this.props.match.params.id,
    }
    graphqlClient.fetch(getCard, vars)
      .then(res => {
        const resCar = res.offer;
        if (!resCar) {
          appConfig.runtimeConfig.carNotFound = true;
        }
        const car = {
          attrs: {
            main: resCar.edition.description[0].features,
            body: resCar.edition.description[1].features,
            interior: resCar.edition.description[2].features,
            safety: resCar.edition.description[3].features,
            equip: resCar.edition.description[4].features,
            warranty: resCar.edition.description.find(item => item.name === 'Гарантия').features,
            tech: resCar.edition.specifications
          },
          availability: resCar.availability,
          brand: clientConfig.searchTextReplace
              ? searchTextReplace(
                  resCar.edition.make.name,
                  clientConfig.searchTextReplace
              )
              : resCar.edition.make.name,
          model: clientConfig.searchAltModelVisible && resCar.dealerModelName
              ? resCar.dealerModelName
              : resCar.edition.model.name,
          edition: resCar.edition,
          retailPrice: resCar.prices.sale.amount,
          basePrice: resCar.prices.base.amount,
          retailPriceVisible: resCar.prices.sale.visible,
          basePriceVisible: resCar.prices.base.visible,
          maxPrice:
              Math.max(
                  resCar.prices.sale.amount || 0,
                  resCar.prices.base.amount || 0
              ),
          minPrice:
              Math.min(
                  resCar.prices.sale.amount || 0,
                  resCar.prices.base.amount || 0
              ) ||
              resCar.prices.sale.amount ||
              0,
          marketingPrice: resCar.prices.marketing.amount || 0,
          creditPrice:
              parseInt(
                  ((clientConfig.searchPriceType === 2
                      ? Math.max(
                          resCar.prices.sale.amount || 0,
                          resCar.prices.base.amount || 0
                      )
                      : resCar.prices.sale.amount || 0) /
                      100) *
                  clientConfig.searchCreditValue
              ) || 0,
          color: resCar.color.name,
          complectation: resCar.edition.versionName,
          location: resCar.dealer.region.name,
          special: resCar.special,
          phone: resCar.dealer.phoneNumber.replace(/[^\/\d]/g, ""),
          dealer_id: resCar.dealer.id,
          dealer: resCar.dealer,
          description: resCar.comment,
          extra: resCar.additionalEquipment,
          id: resCar.id,
          labels: {special: resCar.special},
          photos: resCar.photoUrls,
          image: resCar.photoUrls.length > 0 ? resCar.photoUrls[0] : resCar.catalogPhotoUrl,
          sku: resCar.sku,
          uptodate: resCar.updateAt,
          year: resCar.year
        }
        this.setState({ loading: false, car: car })
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, car } = this.state;

    if (loading) {
      return null;
    }

    if (!car) {
      return <Redirect to="/" />;
    }

    if (!canView(this.context.dealersIdList, car.dealer.expression)) {
      return <AccessDenied />;
    }

    addToDataLayer('checkout', {
      event: "checkout",
      ecommerce: {
        checkout: {
          products: [
            {
              id: "" + car.id,
              price: car.retailPrice.toFixed(2),
              quantity: 1
            }
          ]
        }
      }
    });
    return (
      <div id="search" className="dark">
        <CloseButton
          to={`/car/${car.id}`}
          wrappedComponentRef={el => (this.close = el)}
        />
        <OfferForm car={car} onClose={e => this.close.click(e)} />
        <AutoHeight />
      </div>
    );
  }
}

OfferPage.contextType = context;

export default withConfig(OfferPage);
